<template lang="pug">
.expense-list-actions
  v-menu(
    left
    offset-y
    v-if="hasActions"
  )
    template( v-slot:activator="{ on, attrs }" )
      v-btn(
        color="white"
        v-bind="attrs"
        v-on="on"
        depressed
        small
        fab
      )
        v-icon mdi-dots-vertical
    v-list.divide-y
      v-list-item(
        v-if="expense.actions.edit"
        @click="clickRow(expense.id)"
        class="hover:bg-gray-200 cursor-pointer"
      )
        v-list-item-icon
          v-icon( color="primary" ) mdi-square-edit-outline
        v-list-item-content
          v-list-item-title.cursor-pointer Edit

      v-list-item(
        v-if="expense.actions.reject"
        @click="openExpenseReject = true"
        class="hover:bg-gray-200 cursor-pointer"
      )
        v-list-item-icon
          v-icon( color="red" ) mdi-close-circle-outline
        v-list-item-content
          v-list-item-title.cursor-pointer Reject

      v-list-item(
        v-if="expense.actions.revert"
        @click="revertAction(expense)"
        class="hover:bg-gray-200 cursor-pointer"
      )
        v-list-item-icon
          v-icon( color="grey-darken-4" ) mdi-undo-variant
        v-list-item-content
          v-list-item-title.cursor-pointer Revert

      v-list-item(
        v-if="expense.actions.submit"
        @click="runAction(expense.id, 'submitted')"
        class="hover:bg-gray-200 cursor-pointer"
      )
        v-list-item-icon
          v-icon( color="primary" ) mdi-file-document-arrow-right-outline
        v-list-item-content
          v-list-item-title.cursor-pointer Submit

      v-list-item(
        v-if="expense.actions.approve"
        @click="runAction(expense.id, 'approved')"
        class="hover:bg-gray-200 cursor-pointer"
      )
        v-list-item-icon
          v-icon( color="green" ) mdi-check
        v-list-item-content
          v-list-item-title.cursor-pointer Approve

      v-list-item(
        v-if="expense.actions.confirm"
        @click="runAction(expense.id, 'confirmed')"
        class="hover:bg-gray-200 cursor-pointer"
      )
        v-list-item-icon
          v-icon( color="blue" ) mdi-check-all
        v-list-item-content
          v-list-item-title.cursor-pointer Confirm

      v-list-item(
        v-if="expense.actions.delete"
        @click="deleteExpense(expense)"
        class="hover:bg-gray-200 cursor-pointer"
      )
        v-list-item-icon
          v-icon( color="blue-grey" ) mdi-trash-can-outline
        v-list-item-content
          v-list-item-title.cursor-pointer Delete

  ExpenseRejectDialog(
    :id="expense.id"
    :isOpen="openExpenseReject"
    @close="openExpenseReject = false; findExpenses()"
    v-if="openExpenseReject"
  )

</template>

<script>
import { computed, ref, toRefs } from '@vue/composition-api'
import ExpenseRejectDialog from './Expense.Reject.Dialog.vue'

export default {
  name: 'ExpenseListActions',

  props: ['listExpense', 'findExpenses'],

  components: {
    ExpenseRejectDialog
  },

  setup(props, { emit, root: { $store, $snackError, $snackSuccess }}) {
    const openExpenseReject = ref(false)
    const { listExpense: expense } = toRefs(props)

    const hasActions = computed(() => {
      const { actions } = expense.value;
      return actions.hasActions
    });

    const deleteExpense = async ({ id, status }) => {
      try {
        const params = { query: { disableSoftDelete: (status === 'incomplete') } };
        await $store.dispatch('expenses/remove', [id, params])
        props.findExpenses();
        $snackSuccess('Successfully deleted');
      } catch(e) {
        console.log(e);
      }
    }

    const revertAction = async ({ id, status }) => {
      try {
        await $store.dispatch('expenses/patch', [id, { reverted: { status } }]);
        props.findExpenses();
        $snackSuccess('Successfully updated');
      } catch({ name, message }) {
        if (name === 'GeneralError')
          $snackError(message)
      }
    }

    const runAction = async (id, status) => {
      try {
        await $store.dispatch('expenses/patch', [id, { status, note: null }]);
        props.findExpenses();
        $snackSuccess('Successfully updated');
      } catch({ name, message }) {
        if (name === 'GeneralError')
          $snackError(message)
      }
    }

    const clickRow = (id) => {
      emit('click:row', { id })
    }

    return {
      deleteExpense,
      runAction,
      revertAction,
      clickRow,

      hasActions,
      openExpenseReject,
      expense
    }
  }
}
</script>

<style>
</style>